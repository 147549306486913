import React from "react";
import { Spinner } from "react-bootstrap";
import { navigate } from "gatsby";

// styles
import * as styles from "./DoctorDetails.module.css";

const DoctorDetails = props => {
  return (
    <div className="container twinContainer">
      {props.loading ? (
        <div
          className="d-block text-center"
          style={{ height: "95vh", paddingTop: "30%" }}
        >
          <Spinner animation="grow" role="status" />
        </div>
      ) : (
        <div
          className={
            props.appHeader === "no" && props.twinApp === "yes"
              ? `${styles.twinDocDtls_sec_twinApp}`
              : `${styles.twinDocDtls_sec}`
          }
        >
          {/* ------------- BACK - NAVIGATION --------------- */}
          <div>
            {/* desktop only */}
            <div className={styles.twinDocDtls_backNav_desk}>
              <span
                className={`icon-11`}
                aria-hidden="true"
                onClick={() => {
                  if (
                    props.appHeader &&
                    props.appHeader === "no" &&
                    props.twinApp === "yes"
                  ) {
                    navigate(
                      `/twin-doctors/?appHeader=${props.appHeader}&twinApp=${props.twinApp}`
                    );
                  } else {
                    navigate("/twin-doctors/");
                  }
                }}
              ></span>
              <p>Meet our {props.total} doctors</p>
            </div>
            {/* mobile only */}
            <div
              className={
                props.appHeader === "no" && props.twinApp === "yes"
                  ? `${styles.twinDocDtls_backNav_twinApp}`
                  : `${styles.twinDocDtls_backNav_mob}`
              }
            >
              <span
                className={`icon-back`}
                style={{ fontSize: "22px", cursor: "pointer" }}
                aria-hidden="true"
                onClick={() => {
                  if (
                    props.appHeader &&
                    props.appHeader === "no" &&
                    props.twinApp === "yes"
                  ) {
                    navigate(
                      `/twin-doctors/?appHeader=${props.appHeader}&twinApp=${props.twinApp}`
                    );
                  } else {
                    navigate("/twin-doctors/");
                  }
                }}
              ></span>
            </div>
          </div>
          {/* HEADING - desktop only */}
          <div className={styles.twinDocDtls_hdng_desk}>
            <h3>Doctor</h3>
          </div>
          {/* LINE - desktop only */}
          <div className={styles.twinDocDtls_dsktpLine_1}></div>
          {/* ----------------- DOCTOR DETAILS --------------- */}
          <div className={`row ${styles.twinDocDtls_docDtlsSec}`}>
            {/* doctor details - image, name, designation, yoe, location */}
            <div className="col-12 col-xs-12 col-md-5 col-lg-5">
              {/* doc detials - image, name, designation */}
              <div className="row">
                {/* doc-image */}
                <div
                  className={`col-12 col-xs-12 col-md-4 col-lg-4 ${styles.twinDocDtls_docImg_sec}`}
                >
                  <img
                    src={props.doctorDetail.profilePhoto}
                    alt=""
                    placeholder="blurred"
                  />
                </div>
                {/* doc-name, designation */}
                <div
                  className={`col-12 col-xs-12 col-md-8 col-lg-8 ${styles.twinDocDtls_docNameDsgn_sec}`}
                >
                  <h3>{props.doctorDetail.name}</h3>
                  <p>{props.doctorDetail.designation}, Twin Health</p>
                </div>
              </div>
              {/* doc details - line1 */}
              <div className={styles.twinDocDtls_docDtls_line1}></div>
              <div>
                {/* doc details - yoe */}
                <div className={styles.twinDocDtls_docDtls_yoeLocation_yoe}>
                  <span className={`icon-breafcase`} aria-hidden="true"></span>
                  <p>{props.doctorDetail.experience} yrs of exp</p>
                </div>
                {/* doc details - line2 */}
                <div className={styles.twinDocDtls_docDtls_line2}></div>
                {/* doc details - location */}
                <div className={styles.twinDocDtls_docDtls_yoeLocation_loctn}>
                  <span className={`icon-location`} aria-hidden="true"></span>
                  <p>{props.doctorDetail.city}</p>
                </div>
              </div>
              {/* doc details - line3 */}
              <div className={styles.twinDocDtls_docDtls_line3}></div>
            </div>
            {/* doctor details - description, cta */}
            <div className="col-12 col-xs-12 col-md-7 col-lg-7">
              {/* doc- description */}
              <div className={styles.twinDocDtls_docDtls_docDscrptn}>
                <p>{props.doctorDetail.description}</p>
              </div>
              {/* cta */}
              <div
                className={
                  props.appHeader === "no" && props.twinApp === "yes"
                    ? `${styles.twinDocDtls_docDtls_ctaTwinApp}`
                    : `${styles.twinDocDtls_docDtls_cta}`
                }
              >
                <button
                  onClick={() => {
                    props.twinApp && props.twinApp === "yes"
                      ? window.twinApp
                        ? window.twinApp.postMessage(
                            JSON.stringify({ action: "dx_home_twin_plan" })
                          )
                        : console.log("no window found")
                      : navigate("/diabetes-quiz");
                  }}
                >
                  <span>Start your journey</span>
                  <span
                    className={`icon-arrow-forward ${styles.twinDocDtls_docDtls_ctaIcon}`}
                    aria-hidden="true"
                  ></span>
                </button>
              </div>
            </div>
          </div>
          {/* -------------- LINE - desktop only ------------------- */}
          <div className={styles.twinDocDtls_dsktpLine_2}></div>
        </div>
      )}
    </div>
  );
};

export default DoctorDetails;
