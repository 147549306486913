// extracted by mini-css-extract-plugin
export var twinDocDtls_backNav_desk = "DoctorDetails-module--twinDocDtls_backNav_desk--j5j1T";
export var twinDocDtls_backNav_mob = "DoctorDetails-module--twinDocDtls_backNav_mob--H0VU+";
export var twinDocDtls_backNav_twinApp = "DoctorDetails-module--twinDocDtls_backNav_twinApp--uNnCW";
export var twinDocDtls_docDtlsSec = "DoctorDetails-module--twinDocDtls_docDtlsSec--tIzds";
export var twinDocDtls_docDtls_cta = "DoctorDetails-module--twinDocDtls_docDtls_cta--udeCz";
export var twinDocDtls_docDtls_ctaIcon = "DoctorDetails-module--twinDocDtls_docDtls_ctaIcon--Q7TTB";
export var twinDocDtls_docDtls_ctaTwinApp = "DoctorDetails-module--twinDocDtls_docDtls_ctaTwinApp--xswPh";
export var twinDocDtls_docDtls_docDscrptn = "DoctorDetails-module--twinDocDtls_docDtls_docDscrptn--7o6f6";
export var twinDocDtls_docDtls_line1 = "DoctorDetails-module--twinDocDtls_docDtls_line1--GEIF4";
export var twinDocDtls_docDtls_line2 = "DoctorDetails-module--twinDocDtls_docDtls_line2--8qhDr";
export var twinDocDtls_docDtls_line3 = "DoctorDetails-module--twinDocDtls_docDtls_line3--98-pq";
export var twinDocDtls_docDtls_yoeLocation_loctn = "DoctorDetails-module--twinDocDtls_docDtls_yoeLocation_loctn--hg6Kp";
export var twinDocDtls_docDtls_yoeLocation_yoe = "DoctorDetails-module--twinDocDtls_docDtls_yoeLocation_yoe--Q8Sh9";
export var twinDocDtls_docImg_sec = "DoctorDetails-module--twinDocDtls_docImg_sec--bXV-n";
export var twinDocDtls_docNameDsgn_sec = "DoctorDetails-module--twinDocDtls_docNameDsgn_sec--+R8l4";
export var twinDocDtls_dsktpLine_1 = "DoctorDetails-module--twinDocDtls_dsktpLine_1--tGlbV";
export var twinDocDtls_dsktpLine_2 = "DoctorDetails-module--twinDocDtls_dsktpLine_2--zD6mY";
export var twinDocDtls_hdng_desk = "DoctorDetails-module--twinDocDtls_hdng_desk--gz4dc";
export var twinDocDtls_sec = "DoctorDetails-module--twinDocDtls_sec--Ke37i";
export var twinDocDtls_sec_twinApp = "DoctorDetails-module--twinDocDtls_sec_twinApp--zJTQw";