import React, { useState, useEffect } from "react";
import axios from "axios";
import queryString from "query-string";

// seo
import Seo from "../components/Seo";

// config
import config from "../config";

// App Header
import AppHeaderTwinApp from "../components/AppHeader/AppHeaderTwinApp";

// components
import DoctorDetails from "../components/TwinDoctors/DoctorDetails/DoctorDetails";

const TwinDoctorDetailsPage = props => {
  const [doctorDetail, setDoctorDetail] = useState({});
  const [loading, setLoading] = useState(false);

  const { id, total, twinApp, appHeader } = queryString.parse(
    props.location.search
  );

  // ------> GET DOCTOR DETAILS <------
  useEffect(() => {
    const getDoctorDetails = async () => {
      const doctorDetailOptions = {
        method: "get",
        url: config.DX_API_BASE_URL + "/api/doctor/" + id,
      };
      try {
        setLoading(true);
        const response = await axios(doctorDetailOptions);
        setDoctorDetail(response.data.data);
        setLoading(false);
      } catch (error) {
        console.error("Error in fetching doctors details", error);
        // setServerError("Something went wrong");
      }
    };
    getDoctorDetails();
  }, [id]);
  return (
    <div className="bgLightgray">
      <Seo
        title="Reverse Diabetes @ Twin | Book an appointment with India's leading Doctors"
        description="Is Diabetes Reversal possible? Book a Personalised Video Consultation with Top Diabetologists & get all your Diabetes-related queries answered."
      />

      {/* Start ---> Header for Twin App */}
      {appHeader === "no" && twinApp === "yes" && (
        <AppHeaderTwinApp text="" isBack={true} bgColor="bgLightgray" />
      )}
      {/* End ---> Header for Twin App */}

      <DoctorDetails
        doctorDetail={doctorDetail}
        loading={loading}
        total={total}
        twinApp={twinApp}
        appHeader={appHeader}
      />
    </div>
  );
};

export default TwinDoctorDetailsPage;
